import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import { IRoleViewModel } from "@/fe-core/meta/interfaces/user";
import {
  ClearRolesAction,
  GetRolesFailureAction,
  GetRolesRequestAction,
  GetRolesSuccessAction,
  userActionTypes,
} from "@/fe-core/meta/types/user";

export const getRolesRequest = (): GetRolesRequestAction => ({
  type: userActionTypes.GET_ROLES_REQUEST,
});

export const getRolesSuccess = (
  payload: IRoleViewModel[]
): GetRolesSuccessAction => ({
  type: userActionTypes.GET_ROLES_SUCCESS,
  payload,
});

export const getRolesFailure = (
  payload: IErrorPayload
): GetRolesFailureAction => ({
  type: userActionTypes.GET_ROLES_FAILURE,
  payload,
});

export const clearRoles = (): ClearRolesAction => ({
  type: userActionTypes.CLEAR_ROLES,
});
