import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import { IEmailResendRequestPayload } from "@/fe-core/meta/interfaces/user/register/signupEmailConfirmation";
import { SignupEmailResendFailureAction, SignupEmailResendRequestAction, SignupEmailResendSuccessAction, userActionTypes } from "@/fe-core/meta/types/user";
import { SignupEmailRequestAction } from "@/fe-core/meta/types/user";

export const signupEmailRequest = (
  payload: string
): SignupEmailRequestAction => ({
  type: userActionTypes.SIGNUP_EMAIL_REQUEST,
  payload,
});

export const signupEmailResendRequest = (
  payload: IEmailResendRequestPayload
): SignupEmailResendRequestAction => ({
  type: userActionTypes.SIGNUP_RESEND_EMAIL_REQUEST,
  payload,
});

export const signupEmailResendSuccess = (): SignupEmailResendSuccessAction => ({
  type: userActionTypes.SIGNUP_RESEND_EMAIL_SUCCESS,
});

export const signupEmailResendFailure = (
  payload: IErrorPayload
): SignupEmailResendFailureAction => ({
  type: userActionTypes.SIGNUP_RESEND_EMAIL_FAILURE,
  payload,
});