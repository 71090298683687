import { IErrorPayload } from '@/fe-core/meta/interfaces/root'
import {
  IGetBonusesPayload,
  IApplyBonusPayload,
  ICancelBonusPayload,
  // IClaimableBonusViewModel,
  IBonus,
  IEligibleOptInBonusPlan,
  IOptInBonusPayload,
  IOptInBonusActionResponsePayload,
  IDepositEligibleBonusPlansRequestPayload,
  IDepositEligibleBonusPlan,
  IRedeemBonusPayload,
  IBonusResponseViewModal,
} from '@/fe-core/meta/interfaces/user'
import {
  userActionTypes,
  GetBonusesRequestAction,
  GetBonusesSuccessAction,
  GetBonusesFailureAction,
  ApplyBonusRequestAction,
  ApplyBonusFailureAction,
  ApplyBonusSuccessAction,
  CancelBonusFailureAction,
  CancelBonusRequestAction,
  CancelBonusSuccessAction,
  ClearBonusMessageRequestAction,
  GetClaimableBonusesRequestAction,
  // GetClaimableBonusesSuccessAction,
  GetClaimableBonusesFailureAction,
  // OpenBonusConfirmationModalAction,
  // CloseBonusConfirmationModalAction,
  GetEligibleOptInBonusPlansRequestAction,
  GetEligibleOptInBonusPlansSuccessAction,
  GetEligibleOptInBonusPlansFailureAction,
  OptInBonusRequestAction,
  OptInBonusSuccessAction,
  OptInBonusFailureAction,
  DepositEligibleBonusPlansRequestAction,
  DepositEligibleBonusPlansSuccessAction,
  DepositEligibleBonusPlansFailureAction,
  RedeemBonusFailureAction,
  RedeemBonusRequestAction,
  RedeemBonusSuccessAction,
  ClearRedeemBonusMessageRequestAction,
  ClearOptInBonusMessageRequestAction,
  ClearCancelBonusMessageRequestAction,
} from '@/fe-core/meta/types/user'

export const getBonusesRequest = (
    payload: IGetBonusesPayload
): GetBonusesRequestAction => ({
  type: userActionTypes.GET_BONUSES_REQUEST,
  payload,
})

export const getBonusesSuccess = (
    payload: IBonusResponseViewModal
): GetBonusesSuccessAction => ({
  type: userActionTypes.GET_BONUSES_SUCCESS,
  payload,
})

export const getBonusesFailure = (
    payload: IErrorPayload
): GetBonusesFailureAction => ({
  type: userActionTypes.GET_BONUSES_FAILURE,
  payload,
})

export const getClaimableBonusesRequest =
    (): GetClaimableBonusesRequestAction => ({
      type: userActionTypes.GET_CLAIMABLE_BONUSES_REQUEST,
    })

// export const getClaimableBonusesSuccess = (
//     payload: IClaimableBonusViewModel[]
// ): GetClaimableBonusesSuccessAction => ({
//   type: userActionTypes.GET_CLAIMABLE_BONUSES_SUCCESS,
//   payload,
// })

export const getClaimableBonusesFailure = (
    payload: IErrorPayload
): GetClaimableBonusesFailureAction => ({
  type: userActionTypes.GET_CLAIMABLE_BONUSES_FAILURE,
  payload,
})

export const getEligibleOptInBonusPlansRequest =
  (): GetEligibleOptInBonusPlansRequestAction => ({
    type: userActionTypes.GET_ELIGIBLE_OPT_IN_BONUS_PLANS_REQUEST,
  });

export const getEligibleOptInBonusPlansSuccess = (
  payload: IEligibleOptInBonusPlan[]
): GetEligibleOptInBonusPlansSuccessAction => ({
  type: userActionTypes.GET_ELIGIBLE_OPT_IN_BONUS_PLANS_SUCCESS,
  payload,
});

export const getEligibleOptInBonusPlansFailure = (
  payload: IErrorPayload
): GetEligibleOptInBonusPlansFailureAction => ({
  type: userActionTypes.GET_ELIGIBLE_OPT_IN_BONUS_PLANS_FAILURE,
  payload,
});

export const depositEligibleBonusPlansRequest = (
  payload: IDepositEligibleBonusPlansRequestPayload
): DepositEligibleBonusPlansRequestAction => ({
  type: userActionTypes.DEPOSIT_ELIGIBLE_BONUS_PLANS_REQUEST,
  payload
});

export const depositEligibleBonusPlansSuccess = (
  payload: IDepositEligibleBonusPlan[]
): DepositEligibleBonusPlansSuccessAction => ({
  type: userActionTypes.DEPOSIT_ELIGIBLE_BONUS_PLANS_SUCCESS,
  payload,
});

export const depositEligibleBonusPlansFailure = (
  payload: IErrorPayload
): DepositEligibleBonusPlansFailureAction => ({
  type: userActionTypes.DEPOSIT_ELIGIBLE_BONUS_PLANS_FAILURE,
  payload,
});

export const optInBonusRequest = (
  payload: IOptInBonusPayload
): OptInBonusRequestAction => ({
  type: userActionTypes.OPT_IN_BONUS_REQUEST,
  payload,
});

export const optInBonusSuccess = (
  payload: IOptInBonusActionResponsePayload
): OptInBonusSuccessAction => ({
  type: userActionTypes.OPT_IN_BONUS_SUCCESS,
  payload,
});

export const optInBonusFailure = (
  payload: IOptInBonusActionResponsePayload
): OptInBonusFailureAction => ({
  type: userActionTypes.OPT_IN_BONUS_FAILURE,
  payload,
});


export const applyBonusRequest = (
    payload: IApplyBonusPayload
): ApplyBonusRequestAction => ({
  type: userActionTypes.APPLY_BONUS_REQUEST,
  payload,
})

export const applyBonusSuccess = (): ApplyBonusSuccessAction => ({
  type: userActionTypes.APPLY_BONUS_SUCCESS,
})

export const applyBonusFailure = (
    payload: IErrorPayload
): ApplyBonusFailureAction => ({
  type: userActionTypes.APPLY_BONUS_FAILURE,
  payload,
})

export const redeemBonusRequest = (
    payload: IRedeemBonusPayload
): RedeemBonusRequestAction => ({
  type: userActionTypes.REDEEM_BONUS_REQUEST,
  payload,
})

export const redeemBonusSuccess = (
  payload: IErrorPayload
): RedeemBonusSuccessAction => ({
  type: userActionTypes.REDEEM_BONUS_SUCCESS,
  payload,
});

export const redeemBonusFailure = (
    payload: IErrorPayload
): RedeemBonusFailureAction => ({
  type: userActionTypes.REDEEM_BONUS_FAILURE,
  payload,
})

export const clearRedeemBonusMessage = (): ClearRedeemBonusMessageRequestAction => ({
  type: userActionTypes.CLEAR_REDEEM_BONUS_MESSAGE,
});

export const clearOptInBonusMessage = (): ClearOptInBonusMessageRequestAction => ({
  type: userActionTypes.CLEAR_OPT_IN_BONUS_MESSAGE_REQUEST,
});
export const clearCancelBonusMessage = (): ClearCancelBonusMessageRequestAction => ({
  type: userActionTypes.CLEAR_CANCEL_BONUS_MESSAGE,
});

export const cancelBonusRequest = (
    payload: ICancelBonusPayload
): CancelBonusRequestAction => ({
  type: userActionTypes.CANCEL_BONUS_REQUEST,
  payload,
})

export const cancelBonusSuccess = (): CancelBonusSuccessAction => ({
  type: userActionTypes.CANCEL_BONUS_SUCCESS,
})

export const cancelBonusFailure = (
    payload: IErrorPayload
): CancelBonusFailureAction => ({
  type: userActionTypes.CANCEL_BONUS_FAILURE,
  payload,
})

export const clearBonusMesage = (): ClearBonusMessageRequestAction => ({
  type: userActionTypes.CLEAR_BONUS_MESSAGE_REQUEST,
})

// export const openBonusConfirmationModal = (
//     payload: IClaimableBonusViewModel
// ): OpenBonusConfirmationModalAction => ({
//   type: userActionTypes.OPEN_BONUS_CONFIRMATION_MODAL,
//   payload,
// })

// export const closeBonusConfirmationModal =
//     (): CloseBonusConfirmationModalAction => ({
//       type: userActionTypes.CLOSE_BONUS_CONFIRMATION_MODAL,
//     })
