import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
  ITransactionHistoryRequest,
  ITransactionHistoryViewModel,
} from "@/fe-core/meta/interfaces/user";
import {
  ClearTransactionHistoryAction,
  TransactionHistoryFailureAction,
  TransactionHistoryRequestAction,
  TransactionHistorySuccessAction,
  userActionTypes,
} from "@/fe-core/meta/types/user";

export const transactionHistoryRequest = (
  payload: ITransactionHistoryRequest
): TransactionHistoryRequestAction => ({
  type: userActionTypes.TRANSACTION_HISTORY_REQUEST,
  payload,
});

export const transactionHistorySuccess = (
  payload: ITransactionHistoryViewModel
): TransactionHistorySuccessAction => ({
  type: userActionTypes.TRANSACTION_HISTORY_SUCCESS,
  payload,
});

export const transactionHistoryFailure = (
  payload: IErrorPayload
): TransactionHistoryFailureAction => ({
  type: userActionTypes.TRANSACTION_HISTORY_FAILURE,
  payload,
});

export const clearTransactionHistory = (): ClearTransactionHistoryAction => ({
  type: userActionTypes.CLEAR_TRANSACTION_HISTORY,
});
