import { IRealityCheckPayload } from "@/fe-core/meta/interfaces/user/realityCheck";
import {
  SetRealityCheckAction,
  userActionTypes,
} from "@/fe-core/meta/types/user";

export const setRealityCheck = (
  payload: IRealityCheckPayload
): SetRealityCheckAction => ({
  type: userActionTypes.SET_REALITY_CHECK,
  payload,
});
