import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
  IBettingHistoryPayload,
  IBettingHistoryViewModel,
} from "@/fe-core/meta/interfaces/user";
import {
  BettingHistoryFailureAction,
  BettingHistorySuccessAction,
  BettingHistoryRequestAction,
  userActionTypes,
} from "@/fe-core/meta/types/user";

export const bettingHistoryRequest = (
  payload: IBettingHistoryPayload
): BettingHistoryRequestAction => ({
  type: userActionTypes.BETTING_HISTORY_REQUEST,
  payload,
});

export const bettingHistorySuccess = (
  payload: IBettingHistoryViewModel
): BettingHistorySuccessAction => ({
  type: userActionTypes.BETTING_HISTORY_SUCCESS,
  payload,
});

export const bettingHistoryFailure = (
  payload: IErrorPayload
): BettingHistoryFailureAction => ({
  type: userActionTypes.BETTING_HISTORY_FAILURE,
  payload,
});
