import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
  ITransactionSummaryRequest,
  ITransactionSummaryViewModel,
} from "@/fe-core/meta/interfaces/user";
import {
  ClearTransactionSummaryAction,
  TransactionSummaryFailureAction,
  TransactionSummaryRequestAction,
  TransactionSummarySuccessAction,
  userActionTypes,
} from "@/fe-core/meta/types/user";

export const transactionSummaryRequest = (): TransactionSummaryRequestAction => ({
  type: userActionTypes.TRANSACTION_SUMMARY_REQUEST
});

export const transactionSummarySuccess = (
  payload: ITransactionSummaryViewModel
): TransactionSummarySuccessAction => ({
  type: userActionTypes.TRANSACTION_SUMMARY_SUCCESS,
  payload,
});

export const transactionSummaryFailure = (
  payload: IErrorPayload
): TransactionSummaryFailureAction => ({
  type: userActionTypes.TRANSACTION_SUMMARY_FAILURE,
  payload,
});

export const clearTransactionSummary = (): ClearTransactionSummaryAction => ({
  type: userActionTypes.CLEAR_TRANSACTION_SUMMARY,
});
