import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
  ICashbackBalanceViewModel,
  IRedeemCashbackPayload,
} from "@/fe-core/meta/interfaces/user";
import {
  ClearCashbackBalanceAction,
  ClearRedeemBonusMessageRequestAction,
  GetCashbackBalanceFailureAction,
  GetCashbackBalanceRequestAction,
  GetCashbackBalanceSuccessAction,
  ClearRedeemCashbackMessageRequestAction,
  userActionTypes,
  RedeemBonusRequestAction,
  RedeemBonusSuccessAction,
  RedeemBonusFailureAction,
  RedeemCashbackSuccessAction,
  RedeemCashbackFailureAction,
} from "@/fe-core/meta/types/user";

export const getCashbackBalanceRequest =
  (): GetCashbackBalanceRequestAction => ({
    type: userActionTypes.GET_CASHBACK_BALANCE_REQUEST,
  });

export const clearRedeemCashbackMessage =
  (): ClearRedeemCashbackMessageRequestAction => ({
    type: userActionTypes.CLEAR_REDEEM_CASHBACK_MESSAGE,
  });
export const getCashbackBalanceSuccess = (
  payload: ICashbackBalanceViewModel
): GetCashbackBalanceSuccessAction => ({
  type: userActionTypes.GET_CASHBACK_BALANCE_SUCCESS,
  payload,
});

export const getCashbackBalanceFailure = (
  payload: IErrorPayload
): GetCashbackBalanceFailureAction => ({
  type: userActionTypes.GET_CASHBACK_BALANCE_FAILURE,
  payload,
});

export const redeemCashbackRequest = (
  payload: IRedeemCashbackPayload
): RedeemBonusRequestAction => ({
  type: userActionTypes.REDEEM_CASHBACK_REQUEST,
  payload,
});

export const redeemCashbackSuccess = (
  payload: IErrorPayload
): RedeemCashbackSuccessAction => ({
  type: userActionTypes.REDEEM_CASHBACK_SUCCESS,
  payload,
});

export const redeemCashbackFailure = (
  payload: IErrorPayload
): RedeemCashbackFailureAction => ({
  type: userActionTypes.REDEEM_CASHBACK_FAILURE,
  payload,
});

export const clearCashbackBalance = (): ClearCashbackBalanceAction => ({
  type: userActionTypes.CLEAR_CASHBACK_BALANCE,
});
