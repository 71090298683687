import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
  IValidateCnpRequestPayload,
  IValidateCnpViewModel,
} from "@/fe-core/meta/interfaces/user";
import {
  userActionTypes,
  ValidateCnpFailureAction,
  ValidateCnpRequestAction,
  ValidateCnpSuccessAction,
} from "@/fe-core/meta/types/user";

export const validateCnpRequest = (
  payload: IValidateCnpRequestPayload
): ValidateCnpRequestAction => ({
  type: userActionTypes.VALIDATE_CNP_REQUEST,
  payload,
});

export const validateCnpSuccess = (
  payload: IValidateCnpViewModel
): ValidateCnpSuccessAction => ({
  type: userActionTypes.VALIDATE_CNP_SUCCESS,
  payload,
});

export const validateCnpFailure = (
  payload: IErrorPayload
): ValidateCnpFailureAction => ({
  type: userActionTypes.VALIDATE_CNP_FAILURE,
  payload,
});
