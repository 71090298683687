import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import { IGetWithdrawMethodsResponse, IProcessWithdrawRequestPayload, IProcessWithdrawResponsePayload, IWithdrawViewModel } from "@/fe-core/meta/interfaces/user";
import {
  IPrepareWithdrawRequestPayload,
  IPrepareWithdrawResponsePayload,
  IWithdrawMethod,
} from "../../../../meta/interfaces/user/cashier/withdraw";
import {
  ClearWithdrawAction,
  WithdrawFailureAction,
  WithdrawRequestAction,
  WithdrawSuccessAction,
  GetWithdrawMethodsFailureAction,
  GetWithdrawMethodsRequestAction,
  GetWithdrawMethodsSuccessAction,
  userActionTypes,
  ProcessWithdrawRequestAction,
  OpenProcessWithdrawModalAction,
  CloseProcessWithdrawModalAction,
  ProcessWithdrawSuccessAction,
  ProcessWithdrawFailureAction,
  ResetProcessWithdrawStatusAction,
} from "@/fe-core/meta/types/user";
import {
  PrepareWithdrawFailureAction,
  PrepareWithdrawRequestAction,
  PrepareWithdrawSuccessAction,
} from "@/fe-core/meta/types/user/cashier/prepareWithdraw";

export const withdrawRequest = (payload: number): WithdrawRequestAction => ({
  type: userActionTypes.WITHDRAW_REQUEST,
  payload,
});

export const withdrawSuccess = (
  payload: IWithdrawViewModel
): WithdrawSuccessAction => ({
  type: userActionTypes.WITHDRAW_SUCCESS,
  payload,
});

export const withdrawFailure = (
  payload: IErrorPayload
): WithdrawFailureAction => ({
  type: userActionTypes.WITHDRAW_FAILURE,
  payload,
});

export const getWithdrawMethodsRequest = (
): GetWithdrawMethodsRequestAction => ({
  type: userActionTypes.GET_WITHDRAW_METHODS_REQUEST,
});

export const getWithdrawMethodsSuccess = (
  payload: IGetWithdrawMethodsResponse
): GetWithdrawMethodsSuccessAction => ({
  type: userActionTypes.GET_WITHDRAW_METHODS_SUCCESS,
  payload,
});
export const getWithdrawMethodsFailure = (
  payload: IErrorPayload
): GetWithdrawMethodsFailureAction => ({
  type: userActionTypes.GET_WITHDRAW_METHODS_FAILURE,
  payload,
});

export const resetProcessWithdrawStatus = (): ResetProcessWithdrawStatusAction => ({
  type: userActionTypes.RESET_PROCESS_WITHDRAW_STATUS,
});

export const openProcessWithdrawModal = (
  payload: IWithdrawMethod
): OpenProcessWithdrawModalAction => ({
  type: userActionTypes.OPEN_PROCESS_WITHDRAW_MODAL,
  payload,
});

export const closeProcessWithdrawModal = (): CloseProcessWithdrawModalAction => ({
  type: userActionTypes.CLOSE_PROCESS_WITHDRAW_MODAL,
});

export const processWithdrawRequest = (
  payload: IProcessWithdrawRequestPayload
): ProcessWithdrawRequestAction => ({
  type: userActionTypes.PROCESS_WITHDRAW_REQUEST,
  payload,
});

export const processWithdrawSuccess = (
  payload: IProcessWithdrawResponsePayload
): ProcessWithdrawSuccessAction => ({
  type: userActionTypes.PROCESS_WITHDRAW_REQUEST_SUCCESS,
  payload,
});

export const processWithdrawFailure = (
  payload: IErrorPayload
): ProcessWithdrawFailureAction => ({
  type: userActionTypes.PROCESS_WITHDRAW_REQUEST_FAILURE,
  payload,
});

export const prepareWithdrawRequest = (
  payload: IPrepareWithdrawRequestPayload
): PrepareWithdrawRequestAction => ({
  type: userActionTypes.PREPARE_WITHDRAW_REQUEST,
  payload,
});

export const prepareWithdrawSuccess = (
  payload: IPrepareWithdrawResponsePayload
): PrepareWithdrawSuccessAction => ({
  type: userActionTypes.PREPARE_WITHDRAW_REQUEST_SUCCESS,
  payload,
});

export const prepareWithdrawFailure = (
  payload: IErrorPayload
): PrepareWithdrawFailureAction => ({
  type: userActionTypes.PREPARE_WITHDRAW_REQUEST_FAILURE,
  payload,
});

export const clearWithdraw = (): ClearWithdrawAction => ({
  type: userActionTypes.CLEAR_WITHDRAW,
});