import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
  ISmsRequestPayload,
  ISmsResendRequestPayload,
  ISmsViewModel,
} from "@/fe-core/meta/interfaces/user/register/signupSms";
import {
  userActionTypes,
  SignupSmsRequestAction,
  SignupSmsSuccessAction,
  SignupSmsFailureAction,
  ClearSmsAction,
  SignupSmsResendRequestAction,
  SignupSmsResendSuccessAction,
  SignupSmsResendFailureAction,
  ClearResendAction,
} from "@/fe-core/meta/types/user";

export const signupSmsRequest = (
  payload: ISmsRequestPayload
): SignupSmsRequestAction => ({
  type: userActionTypes.SIGNUP_SMS_REQUEST,
  payload,
});

export const signupSmsSuccess = (
  payload: ISmsViewModel
): SignupSmsSuccessAction => ({
  type: userActionTypes.SIGNUP_SMS_SUCCESS,
  payload,
});

export const signupSmsFailure = (
  payload: IErrorPayload
): SignupSmsFailureAction => ({
  type: userActionTypes.SIGNUP_SMS_FAILURE,
  payload,
});

export const signupSmsResendRequest = (
  payload: ISmsResendRequestPayload
): SignupSmsResendRequestAction => ({
  type: userActionTypes.SIGNUP_SMS_RESEND_REQUEST,
  payload,
});

export const signupSmsResendSuccess = (): SignupSmsResendSuccessAction => ({
  type: userActionTypes.SIGNUP_SMS_RESEND_SUCCESS,
});

export const signupSmsResendFailure = (
  payload: IErrorPayload
): SignupSmsResendFailureAction => ({
  type: userActionTypes.SIGNUP_SMS_RESEND_FAILURE,
  payload,
});

export const clearSms = (): ClearSmsAction => ({
  type: userActionTypes.CLEAR_SMS,
});

export const clearResend = (): ClearResendAction => ({
  type: userActionTypes.CLEAR_RESEND,
});
