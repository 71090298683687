import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
  ISelfExclusionRequestPayload,
  ISelfExclusionViewModel,
} from "@/fe-core/meta/interfaces/user";
import {
  SelfExclusionConfigFailureAction,
  SelfExclusionConfigRequestAction,
  SelfExclusionConfigSuccessAction,
  SelfExclusionFailureAction,
  SelfExclusionRequestAction,
  SelfExclusionSuccessAction,
  userActionTypes,
} from "@/fe-core/meta/types/user";

export const selfExclusionConfigRequest =
  (): SelfExclusionConfigRequestAction => ({
    type: userActionTypes.SELF_EXCLUSION_CONFIG_REQUEST,
  });

export const selfExclusionConfigSuccess = (
  payload: ISelfExclusionViewModel
): SelfExclusionConfigSuccessAction => ({
  type: userActionTypes.SELF_EXCLUSION_CONFIG_SUCCESS,
  payload,
});

export const selfExclusionConfigFailure = (
  payload: IErrorPayload
): SelfExclusionConfigFailureAction => ({
  type: userActionTypes.SELF_EXCLUSION_CONFIG_FAILURE,
  payload,
});

export const selfExclusionRequest = (
  payload: ISelfExclusionRequestPayload
): SelfExclusionRequestAction => ({
  type: userActionTypes.SELF_EXCLUSION_REQUEST,
  payload,
});

export const selfExclusionSuccess = (): SelfExclusionSuccessAction => ({
  type: userActionTypes.SELF_EXCLUSION_SUCCESS,
});

export const selfExclusionFailure = (
  payload: IErrorPayload
): SelfExclusionFailureAction => ({
  type: userActionTypes.SELF_EXCLUSION_FAILURE,
  payload,
});
