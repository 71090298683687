import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
  IChangePasswordPayload,
  IPasswordPolicyViewModel,
  IResetPasswordIsKeyAvailablePayload,
  IResetPasswordIsKeyAvailableRequestPayload,
  IResetPasswordRequestPayload,
  IResetPasswordSuccessPayload,
  IResetPaswordSendEmailRequestPayload,
} from "@/fe-core/meta/interfaces/user";
import {
  ChangePasswordFailureAction,
  ChangePasswordSuccessAction,
  ChangePasswordRequestAction,
  ResetPasswordFailureAction,
  ResetPasswordIsKeyAvailableFailureAction,
  ResetPasswordIsKeyAvailableRequestAction,
  ResetPasswordIsKeyAvailableSuccessAction,
  ResetPasswordRequestAction,
  ResetPasswordSendEmailFailureAction,
  ResetPasswordSendEmailRequestAction,
  ResetPasswordSendEmailSuccessAction,
  ResetPasswordSuccessAction,
  GetPasswordPolicyAction,
  SetPasswordPolicyAction,
  userActionTypes,
  SetShowChangePasswordAction,
  SetHideChangePasswordAction,
} from "@/fe-core/meta/types/user";

export const setShowChangePassword = (): SetShowChangePasswordAction => ({
  type: userActionTypes.SET_SHOW_CHANGE_PASSWORD_MODAL,
});

export const setHideChangePassword = (): SetHideChangePasswordAction => ({
  type: userActionTypes.SET_HIDE_CHANGE_PASSWORD_MODAL,
});

export const changePasswordRequest = (
  payload: IChangePasswordPayload
): ChangePasswordRequestAction => ({
  type: userActionTypes.CHANGE_PASSWORD_REQUEST,
  payload,
});

export const changePasswordSuccess = (): ChangePasswordSuccessAction => ({
  type: userActionTypes.CHANGE_PASSWORD_SUCCESS,
});

export const changePasswordFailure = (
  payload: IErrorPayload
): ChangePasswordFailureAction => ({
  type: userActionTypes.CHANGE_PASSWORD_FAILURE,
  payload,
});

export const resetPasswordSendEmailRequest = (
  payload: IResetPaswordSendEmailRequestPayload
): ResetPasswordSendEmailRequestAction => ({
  type: userActionTypes.RESET_PASSWORD_SEND_EMAIL_REQUEST,
  payload,
});

export const resetPasswordSendEmailSuccess =
  (): ResetPasswordSendEmailSuccessAction => ({
    type: userActionTypes.RESET_PASSWORD_SEND_EMAIL_SUCCESS,
  });

export const resetPasswordSendEmailFailure = (
  payload: IErrorPayload
): ResetPasswordSendEmailFailureAction => ({
  type: userActionTypes.RESET_PASSWORD_SEND_EMAIL_FAILURE,
  payload,
});

export const resetPasswordIsKeyAvailableRequest = (
  payload: IResetPasswordIsKeyAvailableRequestPayload
): ResetPasswordIsKeyAvailableRequestAction => ({
  type: userActionTypes.RESET_PASSWORD_IS_KEY_AVAILABLE_REQUEST,
  payload,
});

export const resetPasswordIsKeyAvailableSuccess = (
  payload: IResetPasswordIsKeyAvailablePayload
): ResetPasswordIsKeyAvailableSuccessAction => ({
  type: userActionTypes.RESET_PASSWORD_IS_KEY_AVAILABLE_SUCCESS,
  payload,
});

export const resetPasswordIsKeyAvailableFailure = (
  payload: IErrorPayload
): ResetPasswordIsKeyAvailableFailureAction => ({
  type: userActionTypes.RESET_PASSWORD_IS_KEY_AVAILABLE_FAILURE,
  payload,
});

export const resetPasswordRequest = (
  payload: IResetPasswordRequestPayload
): ResetPasswordRequestAction => ({
  type: userActionTypes.RESET_PASSWORD_REQUEST,
  payload,
});

export const resetPasswordSuccess = (
  payload: IResetPasswordSuccessPayload
): ResetPasswordSuccessAction => ({
  type: userActionTypes.RESET_PASSWORD_SUCCESS,
  payload
});

export const resetPasswordFailure = (
  payload: IErrorPayload
): ResetPasswordFailureAction => ({
  type: userActionTypes.RESET_PASSWORD_FAILURE,
  payload,
});

export const getPasswordPolicyRequest = (): GetPasswordPolicyAction => ({
  type: userActionTypes.PASSWORD_POLICY_REQUEST,
});

export const setPasswordPolicy = (
  payload: IPasswordPolicyViewModel
): SetPasswordPolicyAction => ({
  type: userActionTypes.PASSWORD_POLICY_SET,
  payload,
});
