import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
  IDepositRequestPayload,
  IDepositViewModel,
  IGetDepositMethodsResponse,
} from "@/fe-core/meta/interfaces/user";
import {
  ClearDepositAction,
  DepositFailureAction,
  DepositRequestAction,
  DepositSuccessAction,
  GetDepositMethodsFailureAction,
  GetDepositMethodsRequestAction,
  GetDepositMethodsSuccessAction,
  SetCloseDepositModalAction,
  SetOpenDepositModalAction,
  userActionTypes,
} from "@/fe-core/meta/types/user";

export const setOpenDepositModal = (): SetOpenDepositModalAction => ({
  type: userActionTypes.SET_OPEN_DEPOSIT_MODAL,
});

export const setCloseDepositModal = (): SetCloseDepositModalAction => ({
  type: userActionTypes.SET_CLOSE_DEPOSIT_MODAL,
});

export const getDepositMethodsRequest = (
): GetDepositMethodsRequestAction => ({
  type: userActionTypes.GET_DEPOSIT_METHODS_REQUEST,
});

export const getDepositMethodsSuccess = (
  payload: IGetDepositMethodsResponse
): GetDepositMethodsSuccessAction => ({
  type: userActionTypes.GET_DEPOSIT_METHODS_SUCCESS,
  payload,
});
export const getDepositMethodsFailure = (
  payload: IErrorPayload
): GetDepositMethodsFailureAction => ({
  type: userActionTypes.GET_DEPOSIT_METHODS_FAILURE,
  payload,
});

export const depositRequest = (
  payload: IDepositRequestPayload
): DepositRequestAction => ({
  type: userActionTypes.DEPOSIT_REQUEST,
  payload,
});

export const depositSuccess = (
  payload: IDepositViewModel
): DepositSuccessAction => ({
  type: userActionTypes.DEPOSIT_SUCCESS,
  payload,
});

export const depositFailure = (
  payload: IErrorPayload
): DepositFailureAction => ({
  type: userActionTypes.DEPOSIT_FAILURE,
  payload,
});

export const clearDeposit = (): ClearDepositAction => ({
  type: userActionTypes.CLEAR_DEPOSIT,
});
