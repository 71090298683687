import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
  ICasinoHistoryPayload,
  ICasinoHistoryViewModel,
} from "@/fe-core/meta/interfaces/user";
import {
  CasinoHistoryFailureAction,
  CasinoHistorySuccessAction,
  CasinoHistoryRequestAction,
  userActionTypes,
  GetCasinoHistoryMoreRequestAction,
  GetCasinoHistoryMoreSuccessAction,
  GetCasinoHistoryMoreFailureAction,
} from "@/fe-core/meta/types/user";

export const casinoHistoryRequest = (
  payload: ICasinoHistoryPayload
): CasinoHistoryRequestAction => ({
  type: userActionTypes.CASINO_HISTORY_REQUEST,
  payload,
});

export const casinoHistorySuccess = (
  payload: ICasinoHistoryViewModel
): CasinoHistorySuccessAction => ({
  type: userActionTypes.CASINO_HISTORY_SUCCESS,
  payload,
});

export const casinoHistoryFailure = (
  payload: IErrorPayload
): CasinoHistoryFailureAction => ({
  type: userActionTypes.CASINO_HISTORY_FAILURE,
  payload,
});

export const getCasinoHistoryMoreRequest = (
  payload: ICasinoHistoryPayload
): GetCasinoHistoryMoreRequestAction => ({
  type: userActionTypes.GET_CASINO_HISTORY_MORE_REQUEST,
  payload,
});

export const getCasinoHistoryMoreSuccess = (
  payload: ICasinoHistoryViewModel
): GetCasinoHistoryMoreSuccessAction => ({
  type: userActionTypes.GET_CASINO_HISTORY_MORE_SUCCESS,
  payload,
});

export const getCasinoHistoryMoreFailure = (
  payload: IErrorPayload
): GetCasinoHistoryMoreFailureAction => ({
  type: userActionTypes.GET_CASINO_HISTORY_MORE_FAILURE,
  payload,
});
