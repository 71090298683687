import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import { IBalanceViewModel } from "@/fe-core/meta/interfaces/user";
import {
  ClearBalanceAction,
  GetBalanceFailureAction,
  GetBalanceRequestAction,
  GetBalanceSuccessAction,
  userActionTypes,
  SubscribeWatchBalanceAction,
  BalanceUpdateAction,
  BalanceChangedPayload,
} from "@/fe-core/meta/types/user";

export const getBalanceRequest = (): GetBalanceRequestAction => ({
  type: userActionTypes.GET_BALANCE_REQUEST,
});

export const getBalanceSuccess = (
  payload: IBalanceViewModel
): GetBalanceSuccessAction => ({
  type: userActionTypes.GET_BALANCE_SUCCESS,
  payload,
});

export const getBalanceFailure = (
  payload: IErrorPayload
): GetBalanceFailureAction => ({
  type: userActionTypes.GET_BALANCE_FAILURE,
  payload,
});

export const clearBalance = (): ClearBalanceAction => ({
  type: userActionTypes.CLEAR_BALANCE,
});

export const subscribeWatchBalance = (): SubscribeWatchBalanceAction => ({
  type: userActionTypes.SUBSCRIBE_WATCH_BALANCE,
});

export const balanceUpdate = (
  balance: BalanceChangedPayload
): BalanceUpdateAction => ({
  type: userActionTypes.BALANCE_UPDATE,
  payload: balance,
});
