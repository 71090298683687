import { userActionTypes } from "@/fe-core/meta/types/user";
import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
  GetLoginsFailureAction,
  GetLoginsRequestAction,
  GetLoginsSuccessAction,
} from "@/fe-core/meta/types/user/logins";
import { ILoginsViewModel } from "@/fe-core/meta/interfaces/user/logins";

export const getLoginsRequest = (): GetLoginsRequestAction => ({
  type: userActionTypes.GET_LOGINS_REQUEST,
});

export const getLoginsSuccess = (
  payload: ILoginsViewModel
): GetLoginsSuccessAction => ({
  type: userActionTypes.GET_LOGINS_SUCCESS,
  payload,
});

export const getLoginsFailure = (
  payload: IErrorPayload
): GetLoginsFailureAction => ({
  type: userActionTypes.GET_LOGINS_FAILURE,
  payload,
});
