import { userActionTypes } from "@/fe-core/meta/types/user";
import { ValidateSignupIpFailureAction, ValidateSignupIpRequestAction, ValidateSignupIpResetAction, ValidateSignupIpSuccessAction } from "@/fe-core/meta/types/user/register/validate/ip";

export const validateSignupIPRequest = (
): ValidateSignupIpRequestAction => ({
  type: userActionTypes.VALIDATE_SIGNUP_IP_REQUEST,
});

export const validateSignupIpSuccess = (
): ValidateSignupIpSuccessAction => ({
  type: userActionTypes.VALIDATE_SIGNUP_IP_SUCCESS,
});

export const validateSignupIpFailure = (
): ValidateSignupIpFailureAction => ({
  type: userActionTypes.VALIDATE_SIGNUP_IP_FAILURE,
});

export const clearSignupPreCheckState = (): ValidateSignupIpResetAction => ({
  type: userActionTypes.RESET_SIGNUP_PRE_CHECK
})