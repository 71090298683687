import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
  IGameSessionsPayload,
  IGameSessionsViewModel,
} from "@/fe-core/meta/interfaces/user";
import {
  GameSessionsFailureAction,
  GameSessionsSuccessAction,
  GameSessionsRequestAction,
  userActionTypes,
} from "@/fe-core/meta/types/user";

export const gameSessionsRequest = (
  payload: IGameSessionsPayload
): GameSessionsRequestAction => ({
  type: userActionTypes.GAME_SESSIONS_REQUEST,
  payload,
});

export const gameSessionsSuccess = (
  payload: IGameSessionsViewModel
): GameSessionsSuccessAction => ({
  type: userActionTypes.GAME_SESSIONS_SUCCESS,
  payload,
});

export const gameSessionsFailure = (
  payload: IErrorPayload
): GameSessionsFailureAction => ({
  type: userActionTypes.GAME_SESSIONS_FAILURE,
  payload,
});
