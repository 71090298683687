import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import { IEmailConfirmationRequestPayload } from "@/fe-core/meta/interfaces/user/register/signupEmailConfirmation";
import {
  userActionTypes,
  SignupEmailConfirmationRequestAction,
  SignupEmailConfirmationSuccessAction,
  SignupEmailConfirmationFailureAction,
  SignupEmailConfirmationClearErrorAction,
} from "@/fe-core/meta/types/user";

export const signupEmailConfirmationRequest = (
  payload: IEmailConfirmationRequestPayload
): SignupEmailConfirmationRequestAction => ({
  type: userActionTypes.SIGNUP_EMAIL_CONFIRMATION_REQUEST,
  payload,
});

export const signupEmailConfirmationSuccess =
  (): SignupEmailConfirmationSuccessAction => ({
    type: userActionTypes.SIGNUP_EMAIL_CONFIRMATION_SUCCESS,
  });

export const signupEmailConfirmationFailure = (
  payload: IErrorPayload
): SignupEmailConfirmationFailureAction => ({
  type: userActionTypes.SIGNUP_EMAIL_CONFIRMATION_FAILURE,
  payload,
});

export const signupEmailConfirmationClearError = (): SignupEmailConfirmationClearErrorAction => ({
  type: userActionTypes.SIGNUP_EMAIL_CONFIRMATION_CLEAR_ERROR,
});
