import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import { ICancelPendingWithdrawalViewModel, IPendingWithdrawalViewModel } from "@/fe-core/meta/interfaces/user";
import {
  CancelPendingWithdrawalFailureAction,
  CancelPendingWithdrawalRequestAction,
  CancelPendingWithdrawalSuccessAction,
  ClearPendingWithdrawalsAction,
  PendingWithdrawalsFailureAction,
  PendingWithdrawalsRequestAction,
  PendingWithdrawalsSuccessAction,
  userActionTypes,
} from "@/fe-core/meta/types/user";

export const pendingWithdrawalsRequest =
  (): PendingWithdrawalsRequestAction => ({
    type: userActionTypes.PENDING_WITHDRAWALS_REQUEST,
  });

export const pendingWithdrawalsSuccess = (
  payload: IPendingWithdrawalViewModel
): PendingWithdrawalsSuccessAction => ({
  type: userActionTypes.PENDING_WITHDRAWALS_SUCCESS,
  payload,
});

export const pendingWithdrawalsFailure = (
  payload: IErrorPayload
): PendingWithdrawalsFailureAction => ({
  type: userActionTypes.PENDING_WITHDRAWALS_FAILURE,
  payload,
});

export const cancelPendingWithdrawalRequest =
  (payload: string): CancelPendingWithdrawalRequestAction => ({
    type: userActionTypes.CANCEL_PENDING_WITHDRAWAL_REQUEST,
    payload
  });

export const cancelPendingWithdrawalSuccess = (
  payload: ICancelPendingWithdrawalViewModel
): CancelPendingWithdrawalSuccessAction => ({
  type: userActionTypes.CANCEL_PENDING_WITHDRAWAL_SUCCESS,
  payload,
});

export const cancelPendingWithdrawalFailure = (
  payload: IErrorPayload
): CancelPendingWithdrawalFailureAction => ({
  type: userActionTypes.CANCEL_PENDING_WITHDRAWAL_FAILURE,
  payload,
});

export const clearPendingWithdrawals = (): ClearPendingWithdrawalsAction => ({
  type: userActionTypes.CLEAR_PENDING_WITHDRAWALS,
});
