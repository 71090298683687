import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
  IQuickSignupPayload,
  ISignupPayload,
  ISignupLocalPayload,
  IUsernameCheckPayload,
  ICheckCredentialRequestPayload
} from "@/fe-core/meta/interfaces/user";
import {
  userActionTypes,
  signupScreens,
  SetActiveSignupScreenAction,
  SetShowSignupAction,
  SetHideSignupAction,
  SignupSuccessAction,
  SignupRequestAction,
  SignupFailureAction,
  QuickSignupRequestAction,
  QuickSignupFailureAction,
  QuickSignupSuccessAction,
  UpdateSignupPayloadAction,
  ClearSignupPayloadAction,
  ClearSignupErrorAction,
  SignupUsernameCheckAction,
  SignupUsernameCheckSuccessAction,
  SignupUsernameCheckFailureAction,
  CheckCredentialRequestAction,
  CheckCredentialEmailSuccessAction,
  CheckCredentialEmailFailureAction,
  CheckCredentialMobileSuccessAction,
  CheckCredentialMobileFailureAction,
} from "@/fe-core/meta/types/user";

export const setShowSignup = (): SetShowSignupAction => ({
  type: userActionTypes.SET_SHOW_SIGNUP_MODAL,
});

export const setHideSignup = (): SetHideSignupAction => ({
  type: userActionTypes.SET_HIDE_SIGNUP_MODAL,
});

export const setActiveSignupScreen = (
  payload: signupScreens
): SetActiveSignupScreenAction => ({
  type: userActionTypes.SET_ACTIVE_SIGNUP_SCREEN,
  payload,
});

export const updateSignUpPayload = (
  payload: ISignupLocalPayload
): UpdateSignupPayloadAction => ({
  type: userActionTypes.UPDATE_SIGNUP_PAYLOAD,
  payload,
});

export const clearSignUpPayload = (): ClearSignupPayloadAction => ({
  type: userActionTypes.CLEAR_SIGNUP_PAYLOAD,
});

export const clearSignUpError = (): ClearSignupErrorAction => ({
  type: userActionTypes.CLEAR_SIGNUP_ERROR,
});

export const signupRequest = (
  payload: ISignupPayload
): SignupRequestAction => ({
  type: userActionTypes.SIGNUP_REQUEST,
  payload,
});

export const usernameAvailability = (
  payload: IUsernameCheckPayload
): SignupUsernameCheckAction => ({
  type: userActionTypes.SIGNUP_USERNAME_CHECK_REQUEST,
  payload,
});

export const usernameAvailabilityCheckSuccess = (): SignupUsernameCheckSuccessAction => ({
  type: userActionTypes.SIGNUP_USERNAME_CHECK_SUCCESS,
});

export const usernameAvailabilityCheckFailure = (
  payload: IErrorPayload
): SignupUsernameCheckFailureAction => ({
  type: userActionTypes.SIGNUP_USERNAME_CHECK_FAILURE,
  payload,
});

export const checkCredentialRequest = (
  payload: ICheckCredentialRequestPayload
): CheckCredentialRequestAction => ({
  type: userActionTypes.CHECK_CREDENTIAL_REQUEST,
  payload,
});

export const checkCredentialEmailSuccess = (): CheckCredentialEmailSuccessAction => ({
  type: userActionTypes.CHECK_CREDENTIAL_EMAIL_SUCCESS,
});

export const checkCredentialEmailFailure = (
  payload: IErrorPayload
): CheckCredentialEmailFailureAction => ({
  type: userActionTypes.CHECK_CREDENTIAL_EMAIL_FAILURE,
  payload,
});

export const checkCredentialMobileSuccess = (): CheckCredentialMobileSuccessAction => ({
  type: userActionTypes.CHECK_CREDENTIAL_MOBILE_SUCCESS,
});

export const checkCredentialMobileFailure = (
  payload: IErrorPayload
): CheckCredentialMobileFailureAction => ({
  type: userActionTypes.CHECK_CREDENTIAL_MOBILE_FAILURE,
  payload,
});

export const signupSuccess = (): SignupSuccessAction => ({
  type: userActionTypes.SIGNUP_SUCCESS,
});

export const signupFailure = (payload: IErrorPayload): SignupFailureAction => ({
  type: userActionTypes.SIGNUP_FAILURE,
  payload,
});

export const quickSignupRequest = (
  payload: IQuickSignupPayload
): QuickSignupRequestAction => ({
  type: userActionTypes.QUICK_SIGNUP_REQUEST,
  payload,
});

export const quickSignupSuccess = (): QuickSignupSuccessAction => ({
  type: userActionTypes.QUICK_SIGNUP_SUCCESS,
});

export const quickSignupFailure = (
  payload: IErrorPayload
): QuickSignupFailureAction => ({
  type: userActionTypes.QUICK_SIGNUP_FAILURE,
  payload,
});
