import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
  IValidateEmailRequestPayload,
  IValidateEmailViewModel,
} from "@/fe-core/meta/interfaces/user";
import {
  userActionTypes,
  ValidateEmailFailureAction,
  ValidateEmailRequestAction,
  ValidateEmailSuccessAction,
} from "@/fe-core/meta/types/user";

export const validateEmailRequest = (
  payload: IValidateEmailRequestPayload
): ValidateEmailRequestAction => ({
  type: userActionTypes.VALIDATE_EMAIL_REQUEST,
  payload,
});

export const validateEmailSuccess = (
  payload: IValidateEmailViewModel
): ValidateEmailSuccessAction => ({
  type: userActionTypes.VALIDATE_EMAIL_SUCCESS,
  payload,
});

export const validateEmailFailure = (
  payload: IErrorPayload
): ValidateEmailFailureAction => ({
  type: userActionTypes.VALIDATE_EMAIL_FAILURE,
  payload,
});
