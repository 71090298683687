import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import { IAddRemoveFavoritesRequestPayload, IFavoritesViewModel } from "@/fe-core/meta/interfaces/user";
import {
  AddFavoriteFailureAction,
  AddFavoriteRequestAction,
  AddFavoriteSuccessAction,
  ClearFavoritesAction,
  DeleteFavoriteFailureAction,
  DeleteFavoriteRequestAction,
  DeleteFavoriteSuccessAction,
  FavoritesPayload,
  GetFavoritesFailureAction,
  GetFavoritesRequestAction,
  GetFavoritesSuccessAction,
  userActionTypes,
} from "@/fe-core/meta/types/user";

export const getFavoritesRequest = (
  payload: FavoritesPayload
): GetFavoritesRequestAction => ({
  type: userActionTypes.GET_FAVORITES_REQUEST,
  payload,
});

export const getFavoritesSuccess = (
  payload: IFavoritesViewModel
): GetFavoritesSuccessAction => ({
  type: userActionTypes.GET_FAVORITES_SUCCESS,
  payload,
});

export const getFavoritesFailure = (
  payload: IErrorPayload
): GetFavoritesFailureAction => ({
  type: userActionTypes.GET_FAVORITES_FAILURE,
  payload,
});

export const addFavoriteRequest = (
  payload: IAddRemoveFavoritesRequestPayload
): AddFavoriteRequestAction => ({
  type: userActionTypes.ADD_FAVORITE_REQUEST,
  payload,
});

export const addFavoriteSuccess = (): AddFavoriteSuccessAction => ({
  type: userActionTypes.ADD_FAVORITE_SUCCESS,
});

export const addFavoriteFailure = (
  payload: IErrorPayload
): AddFavoriteFailureAction => ({
  type: userActionTypes.ADD_FAVORITE_FAILURE,
  payload,
});

export const deleteFavoriteRequest = (
  payload: IAddRemoveFavoritesRequestPayload
): DeleteFavoriteRequestAction => ({
  type: userActionTypes.DELETE_FAVORITE_REQUEST,
  payload,
});

export const deleteFavoriteSuccess = (): DeleteFavoriteSuccessAction => ({
  type: userActionTypes.DELETE_FAVORITE_SUCCESS,
});

export const deleteFavoriteFailure = (
  payload: IErrorPayload
): DeleteFavoriteFailureAction => ({
  type: userActionTypes.DELETE_FAVORITE_FAILURE,
  payload,
});

export const clearFavorites = (): ClearFavoritesAction => ({
  type: userActionTypes.CLEAR_FAVORITES,
});
