import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import { IProfileUpdatePayload, IProfileViewModel } from "@/fe-core/meta/interfaces/user";
import { ProfileUpdateFailureAction, ProfileUpdateRequestAction, ProfileUpdateSuccessAction, SetIsUserProfileUpdatedAction } from '../../../meta/types/user/profile';
import {
  ClearProfileAction,
  GetProfileFailureAction,
  GetProfileRequestAction,
  GetProfileSuccessAction,
  SetHideProfileUpdateModalAction,
  SetShowProfileUpdateModalAction,
  userActionTypes,
} from "@/fe-core/meta/types/user";

export const getProfileRequest = (): GetProfileRequestAction => ({
  type: userActionTypes.GET_PROFILE_REQUEST,
});

export const getProfileSuccess = (
  payload: IProfileViewModel
): GetProfileSuccessAction => ({
  type: userActionTypes.GET_PROFILE_SUCCESS,
  payload,
});

export const getProfileFailure = (
  payload: IErrorPayload
): GetProfileFailureAction => ({
  type: userActionTypes.GET_PROFILE_FAILURE,
  payload,
});

export const clearProfile = (): ClearProfileAction => ({
  type: userActionTypes.CLEAR_PROFILE,
});

export const profileUpdateRequest = (
  payload: IProfileUpdatePayload
): ProfileUpdateRequestAction => ({
  type: userActionTypes.PROFILE_UPDATE_REQUEST,
  payload,
});

export const profileUpdateSuccess = (): ProfileUpdateSuccessAction => ({
  type: userActionTypes.PROFILE_UPDATE_SUCCESS,
});

export const profileUpdateFailure = (
  payload: IErrorPayload
): ProfileUpdateFailureAction => ({
  type: userActionTypes.PROFILE_UPDATE_FAILURE,
  payload,
});

export const setIsUserProfileUpdated =
  (payload: boolean): SetIsUserProfileUpdatedAction => ({
    type: userActionTypes.SET_IS_USER_PROFILE_NEED_TO_UPDATED,
    payload
  });

export const setShowProfileUpdateModal =
  (payload: any): SetShowProfileUpdateModalAction => ({
    type: userActionTypes.SET_SHOW_PROFILE_UPDATE_MODAL,
    payload
  });

export const setHideProfileUpdateModal =
  (): SetHideProfileUpdateModalAction => ({
    type: userActionTypes.SET_HIDE_PROFILE_UPDATE_MODAL,
  });
