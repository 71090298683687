import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
  IValidateUsernameRequestPayload,
  IValidateUsernameViewModel,
} from "@/fe-core/meta/interfaces/user";
import {
  userActionTypes,
  ValidateUsernameFailureAction,
  ValidateUsernameRequestAction,
  ValidateUsernameSuccessAction,
} from "@/fe-core/meta/types/user";

export const validateUsernameRequest = (
  payload: IValidateUsernameRequestPayload
): ValidateUsernameRequestAction => ({
  type: userActionTypes.VALIDATE_USERNAME_REQUEST,
  payload,
});

export const validateUsernameSuccess = (
  payload: IValidateUsernameViewModel
): ValidateUsernameSuccessAction => ({
  type: userActionTypes.VALIDATE_USERNAME_SUCCESS,
  payload,
});

export const validateUsernameFailure = (
  payload: IErrorPayload
): ValidateUsernameFailureAction => ({
  type: userActionTypes.VALIDATE_USERNAME_FAILURE,
  payload,
});
