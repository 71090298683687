import { IBalanceWarningPayload } from "@/fe-core/meta/interfaces/user/balanceWarning";
import {
  SetBalanceWarningAction,
  userActionTypes,
} from "@/fe-core/meta/types/user";

export const setBalanceWarning = (
  payload: IBalanceWarningPayload
): SetBalanceWarningAction => ({
  type: userActionTypes.SET_BALANCE_WARNING,
  payload,
});
