import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import { IDepositBonusesViewModel } from "@/fe-core/meta/interfaces/user";
import {
  DepositBonusesFailureAction,
  DepositBonusesRequestAction,
  DepositBonusesSuccessAction,
  userActionTypes,
} from "@/fe-core/meta/types/user";

export const depositBonusesRequest = (
  payload = { bonusType: "deposit" }
): DepositBonusesRequestAction => ({
  type: userActionTypes.DEPOSIT_BONUSES_REQUEST,
  payload,
});

export const depositBonusesSuccess = (
  payload: IDepositBonusesViewModel
): DepositBonusesSuccessAction => ({
  type: userActionTypes.DEPOSIT_BONUSES_SUCCESS,
  payload,
});

export const depositBonusesFailure = (
  payload: IErrorPayload
): DepositBonusesFailureAction => ({
  type: userActionTypes.DEPOSIT_BONUSES_FAILURE,
  payload,
});
