import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
  IDeleteDepositLimitRequestPayload,
  IDeleteLossLimitRequestPayload,
  ILimitsViewModel,
  ISetDepositLimitRequestPayload,
  ISetLossLimitRequestPayload,
} from "@/fe-core/meta/interfaces/user";
import { userActionTypes } from "@/fe-core/meta/types/user";
import {
  ClearLimitsAction,
  DeleteDepositLimitFailureAction,
  DeleteDepositLimitRequestAction,
  DeleteDepositLimitSuccessAction,
  DeleteLossLimitFailureAction,
  DeleteLossLimitRequestAction,
  DeleteLossLimitSuccessAction,
  GetLimitsFailureAction,
  GetLimitsRequestAction,
  GetLimitsSuccessAction,
  SetDepositLimitFailureAction,
  SetDepositLimitRequestAction,
  SetDepositLimitSuccessAction,
  SetLossLimitFailureAction,
  SetLossLimitRequestAction,
  SetLossLimitSuccessAction,
} from "@/fe-core/meta/types/user/limits";

export const getLimitsRequest = (): GetLimitsRequestAction => ({
  type: userActionTypes.GET_LIMITS_REQUEST,
});

export const getLimitsSuccess = (
  payload: ILimitsViewModel
): GetLimitsSuccessAction => ({
  type: userActionTypes.GET_LIMITS_SUCCESS,
  payload,
});

export const getLimitsFailure = (
  payload: IErrorPayload
): GetLimitsFailureAction => ({
  type: userActionTypes.GET_LIMITS_FAILURE,
  payload,
});

export const setDepositLimitRequest = (
  payload: ISetDepositLimitRequestPayload
): SetDepositLimitRequestAction => ({
  type: userActionTypes.SET_DEPOSIT_LIMITS_REQUEST,
  payload,
});

export const setDepositLimitSuccess = (): SetDepositLimitSuccessAction => ({
  type: userActionTypes.SET_DEPOSIT_LIMITS_SUCCESS,
});

export const setDepositLimitFailure = (
  payload: IErrorPayload
): SetDepositLimitFailureAction => ({
  type: userActionTypes.SET_DEPOSIT_LIMITS_FAILURE,
  payload,
});

export const deleteDepositLimitRequest = (
  payload: IDeleteDepositLimitRequestPayload
): DeleteDepositLimitRequestAction => ({
  type: userActionTypes.DELETE_DEPOSIT_LIMITS_REQUEST,
  payload,
});

export const deleteDepositLimitSuccess =
  (): DeleteDepositLimitSuccessAction => ({
    type: userActionTypes.DELETE_DEPOSIT_LIMITS_SUCCESS,
  });

export const deleteDepositLimitFailure = (
  payload: IErrorPayload
): DeleteDepositLimitFailureAction => ({
  type: userActionTypes.DELETE_DEPOSIT_LIMITS_FAILURE,
  payload,
});

export const setLossLimitRequest = (
  payload: ISetLossLimitRequestPayload
): SetLossLimitRequestAction => ({
  type: userActionTypes.SET_LOSS_LIMITS_REQUEST,
  payload,
});

export const setLossLimitSuccess = (): SetLossLimitSuccessAction => ({
  type: userActionTypes.SET_LOSS_LIMITS_SUCCESS,
});

export const setLossLimitFailure = (
  payload: IErrorPayload
): SetLossLimitFailureAction => ({
  type: userActionTypes.SET_LOSS_LIMITS_FAILURE,
  payload,
});

export const deleteLossLimitRequest = (
  payload: IDeleteLossLimitRequestPayload
): DeleteLossLimitRequestAction => ({
  type: userActionTypes.DELETE_LOSS_LIMITS_REQUEST,
  payload,
});

export const deleteLossLimitSuccess = (): DeleteLossLimitSuccessAction => ({
  type: userActionTypes.DELETE_LOSS_LIMITS_SUCCESS,
});

export const deleteLossLimitFailure = (
  payload: IErrorPayload
): DeleteLossLimitFailureAction => ({
  type: userActionTypes.DELETE_LOSS_LIMITS_FAILURE,
  payload,
});

export const clearLimits = (): ClearLimitsAction => ({
  type: userActionTypes.CLEAR_LIMITS,
});
