import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
  IValidateMobileRequestPayload,
  IValidateMobileViewModel,
} from "@/fe-core/meta/interfaces/user";
import {
  userActionTypes,
  ValidateMobileFailureAction,
  ValidateMobileRequestAction,
  ValidateMobileSuccessAction,
} from "@/fe-core/meta/types/user";

export const validateMobileRequest = (
  payload: IValidateMobileRequestPayload
): ValidateMobileRequestAction => ({
  type: userActionTypes.VALIDATE_MOBILE_REQUEST,
  payload,
});

export const validateMobileSuccess = (
  payload: IValidateMobileViewModel
): ValidateMobileSuccessAction => ({
  type: userActionTypes.VALIDATE_MOBILE_SUCCESS,
  payload,
});

export const validateMobileFailure = (
  payload: IErrorPayload
): ValidateMobileFailureAction => ({
  type: userActionTypes.VALIDATE_MOBILE_FAILURE,
  payload,
});
