import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
  IDocumentsViewModel,
  IDocumentViewModel,
  IUploadDocumentRequest,
  IUserDocumentViewModel,
} from "@/fe-core/meta/interfaces/user/documents";
import { userActionTypes } from "@/fe-core/meta/types/user";
import {
  ClearDocumentsAction,
  ClearUploadDocumentStatusAction,
  CloseUploadDocumentModalAction,
  GetDocumentsFailureAction,
  GetDocumentsRequestAction,
  GetDocumentsSuccessAction,
  GetUserDocumentsFailureAction,
  GetUserDocumentsRequestAction,
  GetUserDocumentsSuccessAction,
  OpenUploadDocumentModalAction,
  UploadDocumentFailureAction,
  UploadDocumentRequestAction,
  UploadDocumentSuccessAction,
} from "@/fe-core/meta/types/user/documents";

export const getDocumentsRequest = (): GetDocumentsRequestAction => ({
  type: userActionTypes.GET_DOCUMENTS_REQUEST,
});

export const getDocumentsSuccess = (
  payload: IDocumentsViewModel
): GetDocumentsSuccessAction => ({
  type: userActionTypes.GET_DOCUMENTS_SUCCESS,
  payload,
});

export const getDocumentsFailure = (
  payload: IErrorPayload
): GetDocumentsFailureAction => ({
  type: userActionTypes.GET_DOCUMENTS_FAILURE,
  payload,
});

export const uploadDocumentRequest = (
  payload: IUploadDocumentRequest
): UploadDocumentRequestAction => ({
  type: userActionTypes.UPLOAD_DOCUMENT_REQUEST,
  payload,
});

export const uploadDocumentSuccess = (
  payload: IUserDocumentViewModel
): UploadDocumentSuccessAction => ({
  type: userActionTypes.UPLOAD_DOCUMENT_SUCCESS,
  payload,
});

export const uploadDocumentFailure = (
  payload: IErrorPayload
): UploadDocumentFailureAction => ({
  type: userActionTypes.UPLOAD_DOCUMENT_FAILURE,
  payload,
});

export const clearUploadDocumentStatus = (): ClearUploadDocumentStatusAction => ({
  type: userActionTypes.CLEAR_UPLOAD_DOCUMENT_STATUS,
});

export const clearDocuments = (): ClearDocumentsAction => ({
  type: userActionTypes.CLEAR_DOCUMENTS,
});

export const openUploadDocumentModal = (
  payload: IDocumentViewModel
): OpenUploadDocumentModalAction => ({
  type: userActionTypes.OPEN_UPLOAD_DOCUMENT_MODAL,
  payload,
});

export const closeUploadDocumentModal = (): CloseUploadDocumentModalAction => ({
  type: userActionTypes.CLOSE_UPLOAD_DOCUMENT_MODAL,
});

export const getUserDocumentsRequest = (): GetUserDocumentsRequestAction => ({
  type: userActionTypes.GET_USER_DOCUMENTS_REQUEST,
});

export const getUserDocumentsSuccess = (
  payload: IUserDocumentViewModel[]
): GetUserDocumentsSuccessAction => ({
  type: userActionTypes.GET_USER_DOCUMENTS_SUCCESS,
  payload,
});

export const getUserDocumentsFailure = (
  payload: IErrorPayload
): GetUserDocumentsFailureAction => ({
  type: userActionTypes.GET_USER_DOCUMENTS_FAILURE,
  payload,
});
