import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
  IPaymentIqCancelTransactionPayload,
  IPaymentIqCancelTransactionViewModel,
  IPaymentIqTransactionHistoryViewModel,
  IPaymentIqTransactionTimeIntervalViewModel,
  ISetPaymentIqTransactionHistoryPendingViewModel,
} from "@/fe-core/meta/interfaces/user";
import {
  CancelPaymentIqTransactionAction,
  CancelPaymentIqTransactionFailureAction,
  CancelPaymentIqTransactionSuccessAction,
  ClearPaymentIqTransactionHistoryAction,
  PaymentIqTransactionHistoryFailureAction,
  PaymentIqTransactionHistorySuccessAction,
  SETPaymentIqTransactionTimeIntervalAction,
  SetPaymentIqTransactionHistoryPendingAction,
  userActionTypes,
} from "@/fe-core/meta/types/user";

export const ecTransactionHistoryRequest = () => ({
  type: userActionTypes.PAYMENT_IQ_TRANSACTION_HISTORY_REQUEST,
});

export const ecTransactionHistorySuccess = (
  payload: IPaymentIqTransactionHistoryViewModel
): PaymentIqTransactionHistorySuccessAction => ({
  type: userActionTypes.PAYMENT_IQ_TRANSACTION_HISTORY_SUCCESS,
  payload,
});

export const setECTransactionHistoryPending = (
  payload: ISetPaymentIqTransactionHistoryPendingViewModel
): SetPaymentIqTransactionHistoryPendingAction => ({
  type: userActionTypes.SET_PAYMENT_IQ_TRANSACTION_HISTORY_PENDING,
  payload,
});

export const setECTrasactionTimeInterval = (
  payload: IPaymentIqTransactionTimeIntervalViewModel
): SETPaymentIqTransactionTimeIntervalAction => ({
  type: userActionTypes.SET_PAYMENT_IQ_TRANSACTION_TIME_INTERVAL,
  payload,
});

export const cancelECTrasactionRequest = (
  payload: IPaymentIqCancelTransactionPayload
): CancelPaymentIqTransactionAction => ({
  type: userActionTypes.CANCEL_PAYMENT_IQ_TRANSACTION_REQUEST,
  payload: payload,
});

export const cancelECTrasactionSuccess = (
  payload: IPaymentIqCancelTransactionViewModel
): CancelPaymentIqTransactionSuccessAction => ({
  type: userActionTypes.CANCEL_PAYMENT_IQ_TRANSACTION_SUCCESS,
  payload,
});
export const cancelECTrasactionFailure = (
  payload: IErrorPayload
): CancelPaymentIqTransactionFailureAction => ({
  type: userActionTypes.CANCEL_PAYMENT_IQ_TRANSACTION_FAILURE,
  payload,
});

export const ecTransactionHistoryFailure = (
  payload: IErrorPayload
): PaymentIqTransactionHistoryFailureAction => ({
  type: userActionTypes.PAYMENT_IQ_TRANSACTION_HISTORY_FAILURE,
  payload,
});

export const clearECTransactionHistory = (): ClearPaymentIqTransactionHistoryAction => ({
  type: userActionTypes.CLEAR_PAYMENT_IQ_TRANSACTION_HISTORY,
});
