import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
  IConsentsViewModel,
  ISetConestsRequestPayload,
} from "@/fe-core/meta/interfaces/user";
import {
  ClearConsentsAction,
  GetConsentsFailureAction,
  GetConsentsRequestAction,
  GetConsentsSuccessAction,
  SetConsentsFailureAction,
  SetConsentsRequestAction,
  SetConsentsSuccessAction,
  userActionTypes,
} from "@/fe-core/meta/types/user";

export const getConsentsRequest = (): GetConsentsRequestAction => ({
  type: userActionTypes.GET_CONSENTS_REQUEST,
});

export const getConsentsSuccess = (
  payload: IConsentsViewModel
): GetConsentsSuccessAction => ({
  type: userActionTypes.GET_CONSENTS_SUCCESS,
  payload,
});

export const getConsentsFailure = (
  payload: IErrorPayload
): GetConsentsFailureAction => ({
  type: userActionTypes.GET_CONSENTS_FAILURE,
  payload,
});

export const setConsentsRequest = (
  payload: ISetConestsRequestPayload
): SetConsentsRequestAction => ({
  type: userActionTypes.SET_CONSENTS_REQUEST,
  payload,
});

export const setConsentsSuccess = (
  payload: IConsentsViewModel
): SetConsentsSuccessAction => ({
  type: userActionTypes.SET_CONSENTS_SUCCESS,
  payload,
});

export const setConsentsFailure = (
  payload: IErrorPayload
): SetConsentsFailureAction => ({
  type: userActionTypes.SET_CONSENTS_FAILURE,
  payload,
});

export const clearConsents = (): ClearConsentsAction => ({
  type: userActionTypes.CLEAR_CONSENTS,
});
